import React from "react";

import './index.scss';

import Home from "./components/Home";

function App() {
	return (
		<div className="App">
			<Home/>
		</div>
	);
}

export default App;
